import React, { useEffect, useState } from "react";
import { GatsbyImageProps, getSrc } from "gatsby-plugin-image";
import { Overlay } from "../Overlay";

import { media } from "./ImageLightbox.module.scss";

interface ImageLightboxProps {
  image?: GatsbyImageProps["image"];
  open: boolean;
  onClose: () => void;
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({
  image,
  open,
  onClose,
}) => {
  const [currentImage, setCurrentImage] = useState<GatsbyImageProps["image"]>(
    null,
  );

  useEffect(() => {
    if (image) {
      setCurrentImage(image);
    }
  }, [image]);
  return (
    <Overlay onClose={onClose} active={open}>
      {currentImage && (
        <div
          className={media}
          onClick={onClose}
          style={
            {
              backgroundImage: `url(${getSrc(currentImage)})`,
            } as React.CSSProperties
          }
        />
      )}
    </Overlay>
  );
};

export { ImageLightbox };
