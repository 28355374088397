import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button } from "../Button";
import { ReactComponent as BurgerSvg } from "../../images/burger.svg";
import { clickOutside } from "../../utils/utils";
import { useTransitionReady } from "../../utils/hooks/transitionReady";

import {
  header,
  logo,
  middle,
  show,
  right,
  open,
  menu,
  menuBtn,
  transition,
  burger,
} from "./Header.module.scss";
import { useSiteContext } from "../SiteContext";
import { Progress } from "../Quiz/Progress";

const MENU_ITEMS = [
  {
    to: "/#tour",
    label: "The Tour",
    section: "tour",
    color: "var(--color-green)",
  },
  {
    to: "/#quiz",
    label: "The Quiz",
    section: "quiz",
    color: "var(--color-beige)",
  },
  {
    to: "/#resources",
    label: "Resources",
    section: "resources",
    color: "var(--color-light-purple)",
  },
  {
    to: "/#support",
    label: "Support",
    section: "support",
    color: "var(--color-beige)",
  },
  {
    to: "/#about",
    label: "About",
    section: "about",
    color: "var(--color-light-blue)",
  },
];

const Header: React.FC<{ exhibition?: boolean }> = ({ exhibition }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { currentSection } = useSiteContext();

  const transitionReady = useTransitionReady();

  useEffect(() => {
    if (menuOpen) {
      clickOutside(menuRef.current, () => {
        setMenuOpen(false);
      });
    }
  }, [menuOpen]);

  const onToggleMenu = useCallback(() => {
    setMenuOpen((s) => !s);
  }, []);

  const menuText = useMemo(() => {
    const currentItem = MENU_ITEMS.find(
      (item) => item.section === currentSection,
    );

    if (!currentItem) {
      return {
        color: "var(--color-light-purple)",
        label: "Menu",
        section: "",
      };
    }
    return currentItem;
  }, [currentSection]);

  return (
    <header
      className={classNames(header, {
        [open]: menuOpen,
        [transition]: transitionReady,
      })}
    >
      <h1 className={logo}>Meat Your Persona</h1>

      <div
        className={classNames(middle, { [show]: menuText.section === "quiz" })}
      >
        <Progress />
      </div>

      <div
        className={right}
        ref={menuRef}
        style={{ "--menu-item-color": menuText.color } as React.CSSProperties}
      >
        {!exhibition && (
          <>
            <Button
              className={menuBtn}
              onClick={onToggleMenu}
              rightSlot={menuOpen ? <BurgerSvg className={burger} /> : null}
            >
              {menuText.label}
            </Button>

            <ul className={menu}>
              {MENU_ITEMS.map((item) => (
                <li
                  key={item.to}
                  style={
                    { "--menu-item-color": item.color } as React.CSSProperties
                  }
                  onClick={() => setMenuOpen(false)}
                >
                  <Button to={item.to}>{item.label}</Button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </header>
  );
};

export { Header };
