import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

import { COOKIE_NAME, loadScripts } from "../../utils/analytics";

import * as styles from "./Consent.module.scss";

const Consent: React.FC = () => {
  return (
    <CookieConsent
      onAccept={() => {
        loadScripts();
      }}
      buttonText="Okay, no problem"
      cookieName={COOKIE_NAME}
      cookieValue={1}
      buttonClasses={styles.btn}
      declineButtonClasses={styles.decline}
      containerClasses={styles.container}
      contentClasses={styles.content}
      declineButtonText="No thanks"
      disableStyles
      enableDeclineButton
    >
      This site uses cookies. <Link to="/#privacy">More information</Link>
    </CookieConsent>
  );
};

export { Consent };
