import React, { useMemo } from "react";

import { Link } from "gatsby";
import classNames from "classnames";

import * as styles from "./Button.module.scss";
import { InternalExternalLink } from "../InternalExternalLink";

interface ButtonProps {
  className?: string;
  onClick?: () => void;
  to?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: "normal" | "large";
  rightSlot?: React.ReactElement;
  basic?: boolean;
  invert?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  to,
  disabled,
  onClick,
  fullWidth,
  rightSlot,
  size = "normal",
  basic,
  invert,
  className,
}) => {
  const cls = classNames(
    styles.button,
    [styles[size]],
    {
      [styles.disabled]: disabled,
      [styles.fullWidth]: fullWidth,
      [styles.basic]: basic,
      [styles.invert]: invert,
    },
    className,
  );

  const inner = useMemo(() => {
    return (
      <>
        <span>{children}</span>
        {rightSlot && <span className={styles.right}>{rightSlot}</span>}
      </>
    );
  }, [children, rightSlot]);

  if (onClick) {
    return (
      <button className={cls} onClick={onClick}>
        {inner}
      </button>
    );
  }

  if (to) {
    return (
      <InternalExternalLink className={cls} url={to}>
        {inner}
      </InternalExternalLink>
    );
  }

  return <span className={cls}>{inner}</span>;
};

export { Button };
