import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-plugin-image";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UnlistenFunction } from "../../utils/events";
import { ImageLightbox } from "../ImageLightbox";
import { Overlay } from "../Overlay";
import { RecipeCard, RecipeObject } from "../RecipeCard";

interface RecipesQuery {
  recipes: {
    edges: Array<{
      node: {
        fileAbsolutePath: string;
        frontmatter: RecipeObject;
      };
    }>;
  };
}

type ActiveLightboxItem = {
  image: GatsbyImageProps["image"];
};

interface SiteContextProps {
  registerSection: (el: HTMLElement) => UnlistenFunction;
  currentSection: string | null;
  currentQuestion: number;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<number>>;
  setRecipe: (recipeId: string) => void;
  setActiveLightboxItem: (props: ActiveLightboxItem) => void;
}

const SiteContext = createContext<SiteContextProps>({
  registerSection: () => () => false,
  currentSection: null,
  currentQuestion: 0,
  setCurrentQuestion: () => false,
  setRecipe: () => false,
  setActiveLightboxItem: () => false,
});

const SiteContainer: React.FC = ({ children }) => {
  const pageIO = useRef<IntersectionObserver>(null);
  const holdingEls = useRef<HTMLElement[]>([]);
  const [currentSection, setCurrentSection] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [activeRecipe, setActiveRecipe] = useState<RecipeObject>(null);
  const [
    activeLightboxItem,
    setActiveLightboxItem,
  ] = useState<ActiveLightboxItem>(null);

  const { recipes, audio } = useStaticQuery<RecipesQuery>(graphql`
    query RecipesQuery {
      recipes: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/recipes/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              title
              serves
              color
              ingredients
              method {
                step
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const onIntersect: IntersectionObserverCallback = (entries) => {
      const sections = entries
        .filter((entry) => entry.isIntersecting)
        .sort((a, b) => {
          return a.boundingClientRect.y - b.boundingClientRect.y;
        })
        .map((e) => e.target.id);

      if (sections.length) {
        setCurrentSection(sections.pop());
      }
    };

    pageIO.current = new IntersectionObserver(onIntersect, {
      threshold: [0.1],
    });
    holdingEls.current.forEach((el) => pageIO.current.observe(el));

    return () => {
      pageIO.current.disconnect();
    };
  }, []);

  const registerSection = useCallback((el: HTMLElement) => {
    if (pageIO.current) {
      pageIO.current.observe(el);
    } else {
      holdingEls.current.push(el);
    }

    return () => {
      pageIO.current.unobserve(el);
    };
  }, []);

  const setRecipe = useCallback(
    (recipeId: string) => {
      const recipe = recipes.edges.find(({ node }) =>
        node.fileAbsolutePath.includes(recipeId),
      );

      if (recipe) {
        setActiveRecipe(recipe.node.frontmatter);
      }
    },
    [recipes],
  );

  return (
    <SiteContext.Provider
      value={{
        registerSection,
        currentSection,
        currentQuestion,
        setCurrentQuestion,
        setRecipe,
        setActiveLightboxItem,
      }}
    >
      <Overlay onClose={() => setActiveRecipe(null)} active={!!activeRecipe}>
        <RecipeCard recipe={activeRecipe} />
      </Overlay>

      <ImageLightbox
        onClose={() => setActiveLightboxItem(null)}
        image={activeLightboxItem?.image || undefined}
        open={!!activeLightboxItem}
      />
      {children}
    </SiteContext.Provider>
  );
};

export const useSiteContext = () => useContext(SiteContext);

export { SiteContext, SiteContainer };
