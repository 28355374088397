import { useEffect, useState } from "react";

export const useTransitionReady = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      setReady(true);
    });
  }, []);

  return ready;
};
