export enum PersonaType {
  BLT = "blt",
  HappyEater = "happyeater",
  ChickenSupreme = "chickensupreme",
  PartTimeCarnivore = "parttimecarnivore",
  AdventurousEater = "adventurouseater",
  SaladSpinner = "saladspinner",
}

interface PersonaAnswer {
  value: PersonaType;
  label: string;
}

interface PersonaQuestion {
  label: string;
  answers: PersonaAnswer[];
}

export const PERSONA_LABELS: Record<PersonaType, string> = {
  [PersonaType.BLT]: "The BLT",
  [PersonaType.HappyEater]: "The Happy Eater",
  [PersonaType.ChickenSupreme]: "The Chicken Supreme",
  [PersonaType.PartTimeCarnivore]: "The Part-Time Carnivore",
  [PersonaType.AdventurousEater]: "The Adventurous Eater",
  [PersonaType.SaladSpinner]: "The Salad Spinner",
};

export const PERSONA_DESCRIPTIONS: Record<PersonaType, string> = {
  [PersonaType.BLT]: "Breakfast, Lunch and Tea - it’s not a meal without meat",
  [PersonaType.HappyEater]: "You are what you eat - and you eat only the best",
  [PersonaType.ChickenSupreme]:
    "Your body is a temple - and it’s powered by protein",
  [PersonaType.PartTimeCarnivore]: "You love meat - as an occasional treat",
  [PersonaType.AdventurousEater]: "You’ll try anything once",
  [PersonaType.SaladSpinner]: "You’re a meat-free magician",
};

export const Questions: PersonaQuestion[] = [
  {
    label: "How often do you eat meat?",
    answers: [
      {
        label: "It isn't a meal without meat",
        value: PersonaType.BLT,
      },
      {
        label: "Most days...",
        value: PersonaType.HappyEater,
      },
      {
        label: "A couple of times a week",
        value: PersonaType.ChickenSupreme,
      },
      {
        label: "Now and then...",
        value: PersonaType.PartTimeCarnivore,
      },
      {
        label: "Only when I'm out...",
        value: PersonaType.AdventurousEater,
      },
      {
        label: "Never!",
        value: PersonaType.SaladSpinner,
      },
    ],
  },
  {
    label: "WHAT COULD YOU NOT LIVE WITHOUT? ",
    answers: [
      {
        label: "BEEF ME UP!",
        value: PersonaType.BLT,
      },
      {
        label: "CHICKEN LICKIN’!",
        value: PersonaType.ChickenSupreme,
      },
      {
        label: "ALL THINGS PORK!",
        value: PersonaType.HappyEater,
      },
      {
        label: "LAMB, I LOVE EWE!",
        value: PersonaType.AdventurousEater,
      },
      {
        label: "SEAFOOD… AND I EAT IT! ",
        value: PersonaType.PartTimeCarnivore,
      },
      {
        label: "CHEESE PLEASE!",
        value: PersonaType.SaladSpinner,
      },
    ],
  },
  {
    label: "HOW MUCH DO YOU THINK ABOUT WHAT YOU EAT?",
    answers: [
      {
        label: "I’VE GOT FOOD ON THE BRAIN!",
        value: PersonaType.HappyEater,
      },
      {
        label: "FOOD IS MY FUEL...",
        value: PersonaType.ChickenSupreme,
      },
      {
        label: "I’M NOT FUSSY...",
        value: PersonaType.PartTimeCarnivore,
      },
      {
        label: "I’M EASILY TEMPTED… ",
        value: PersonaType.BLT,
      },
      {
        label: "I’LL TRY ANYTHING ONCE...",
        value: PersonaType.AdventurousEater,
      },
      {
        label: "I’LL HAVE WHATEVER SHE’S HAVING… ",
        value: PersonaType.SaladSpinner,
      },
    ],
  },
  {
    label: "WHAT WILL TICKLE YOUR TASTEBUDS IN THE FUTURE?",
    answers: [
      {
        label: "PLANT-BASED EATING",
        value: PersonaType.SaladSpinner,
      },
      {
        label: "LAB-GROWN MEATS",
        value: PersonaType.BLT,
      },
      {
        label: "INSECTS FOR PROTEIN",
        value: PersonaType.ChickenSupreme,
      },
      {
        label: "BEEF-FREE MENUS",
        value: PersonaType.PartTimeCarnivore,
      },
      {
        label: "FAKE IT TILL YOU MAKE IT…",
        value: PersonaType.AdventurousEater,
      },
      {
        label: "EAT LESS AND BETTER...",
        value: PersonaType.HappyEater,
      },
    ],
  },
  {
    label: "WHAT IS MOST IMPORTANT TO YOU?",
    answers: [
      {
        label: "THE AIR I BREATHE...",
        value: PersonaType.BLT,
      },
      {
        label: "THE WATER I DRINK...",
        value: PersonaType.AdventurousEater,
      },
      {
        label: "THE TREES AROUND ME...",
        value: PersonaType.PartTimeCarnivore,
      },
      {
        label: "THE BIRDS AND THE BEES...",
        value: PersonaType.ChickenSupreme,
      },
      {
        label: "THE CHANGING CLIMATE...",
        value: PersonaType.SaladSpinner,
      },
      {
        label: "KEEPING IT LOCAL...",
        value: PersonaType.HappyEater,
      },
    ],
  },
];
