import React from "react";

import { dot, list, active } from "./Progress.module.scss";

import { Questions } from "../data";
import { useSiteContext } from "../../SiteContext";
import classNames from "classnames";

const questionsRange = new Array(Questions.length).fill(0).map((v, i) => i + 1);

const Progress: React.FC = () => {
  const { currentQuestion } = useSiteContext();

  return (
    <div className={list}>
      {questionsRange.map((v) => (
        <div
          key={v}
          className={classNames(dot, { [active]: currentQuestion === v - 1 })}
        >
          {v}
        </div>
      ))}
    </div>
  );
};

export { Progress };
