import React from "react";
import "../../styles/globals.scss";
import { Consent } from "../Consent";
import { Header } from "../Header";
import { SEO } from "../SEO";
import { SiteContainer } from "../SiteContext";

const Layout: React.FC<{
  className?: string;
  trimmed?: boolean;
  exhibition?: boolean;
}> = ({ children, trimmed, className, exhibition }) => {
  return (
    <SiteContainer>
      <SEO />
      {!trimmed && <Header exhibition={exhibition} />}
      <main className={className}>{children}</main>

      <Consent />
    </SiteContainer>
  );
};

export { Layout };
