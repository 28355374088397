import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

interface MarkdownProps extends React.HTMLAttributes<HTMLDivElement> {
  children: string;
}

const Markdown: React.FC<MarkdownProps> = ({ children, ...props }) => {
  // const renderers = {
  //   // use delete for something cool?
  //   delete: ({ children, node }) => {
  //     const textNode = node.children.find((n) => n.type === "text");

  //     if (!textNode) {
  //       return children;
  //     }

  //     return <InlineHover text={textNode.value} />;
  //   },
  //   code: ({ language, children }) => {
  //     // use code fence blocks to do something very specific
  //     if (language) {
  //       return <>{language}</>;
  //     }

  //     return <code>{children}</code>;
  //   },
  //   link: ({ children, href }) => {
  //     return <InternalExternalLink url={href}>{children}</InternalExternalLink>;
  //   },
  // };

  return (
    <ReactMarkdown
      plugins={[gfm]}
      // renderers={renderers}
      {...props}
    >
      {children}
    </ReactMarkdown>
  );
};

export { Markdown };
