import React, { useEffect, useState } from "react";
import { Markdown } from "../Markdown";

import * as styles from "./RecipeCard.module.scss";

export interface RecipeObject {
  title: string;
  serves: string;
  ingredients: string[];
  method: Array<{ step: string }>;
  color?: string;
}

interface RecipeCardProps {
  recipe?: RecipeObject;
}

const RecipeCard: React.FC<RecipeCardProps> = ({ recipe }) => {
  const [currentRecipe, setCurrentRecipe] = useState<RecipeObject>(recipe);

  useEffect(() => {
    if (recipe) {
      setCurrentRecipe(recipe);
    }
  }, [recipe]);

  return (
    <div
      className={styles.card}
      style={{ "--recipe-color": currentRecipe.color } as React.CSSProperties}
    >
      <h1 className={styles.title}>{currentRecipe.title}</h1>
      <div className={styles.serves}>Serves {currentRecipe.serves}</div>
      <div className={styles.ingredients}>
        <h3>Ingredients</h3>

        <ul>
          {currentRecipe.ingredients.map((i) => (
            <li key={i}>{i}</li>
          ))}
        </ul>
      </div>
      <div className={styles.steps}>
        <h3>Method</h3>

        <ol>
          {currentRecipe.method.map(({ step }, idx) => (
            <li key={idx}>
              <Markdown>{step}</Markdown>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export { RecipeCard };
