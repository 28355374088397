import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import * as styles from "./Overlay.module.scss";
import { registerExits } from "../../utils/utils";

import { ReactComponent as CloseSvg } from "../../images/svg/close.svg";

interface OverlayProps {
  active?: boolean;
  className?: string;
  onClose?: () => void;
}

const Overlay: React.FC<OverlayProps> = ({
  active = false,
  className,
  children,
  onClose,
}) => {
  const [transition, setTransition] = useState(false);
  const [mounted, setMounted] = useState(active);
  const [display, setDisplay] = useState(active);

  useEffect(() => {
    if (active) {
      setMounted(true);

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setDisplay(true);
        });
      });

      if (onClose) {
        return registerExits(() => {
          onClose();
        });
      }
    } else {
      setDisplay(false);
    }
  }, [active, onClose]);

  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setTransition(true);
      });
    });
  }, []);

  const onTransitionEnd = useCallback(
    (e: React.TransitionEvent<HTMLDivElement>) => {
      if (
        (e.target as HTMLDivElement).classList.contains(styles.overlay) &&
        !display
      ) {
        setMounted(false);
      }
    },
    [display],
  );

  if (!mounted) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.overlay,
        {
          [styles.display]: display,
          [styles.transition]: transition,
        },
        className,
      )}
      onTransitionEnd={onTransitionEnd}
    >
      <button onClick={onClose} className={styles.close}>
        <CloseSvg />
      </button>
      <div className={styles.backdrop} onClick={onClose} />
      {children}
    </div>
  );
};

export { Overlay };
